import axios from 'axios'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoadingSuspense from 'src/components/application/LoadingSuspense'
import IntroContainer from 'src/containers/Top/Intro'
import GreenIntroContainer from 'src/containers/TopGreen/Intro'
import { store } from 'src/store'

const PreviewPage: React.FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const { template } = useSelector((state: any) => state.clinic)

  useEffect(() => {
    const url = searchParams.get('url')
    if (!url) {
      return
    }
    axios.get(url).then(({ data }) => {
      store.dispatch({ type: 'clinic/setClinic', payload: data.data })
    })
  }, [])

  useEffect(() => {
    if (template === 'none') navigate('/')
  }, [template])

  return (
    <>
      {(template &&
        {
          default: <IntroContainer />,
          main_color_green: <GreenIntroContainer />,
        }[template]) || <LoadingSuspense />}
    </>
  )
}

export default PreviewPage
