import React from 'react'
import styled from 'styled-components'
import usingImg1 from 'src/assets/images/using-1.png'
import usingImg2 from 'src/assets/images/using-2.png'
import usingImg3 from 'src/assets/images/using-3.png'
import usingImg4 from 'src/assets/images/using-4.png'
import usingImg5 from 'src/assets/images/using-5.png'

const UsingComponent: React.FC = () => {
  const usingList = [
    {
      step: 'STEP 1',
      title: '基本情報・問診の入力',
      content: '基本情報を入力して、 \n問診・注意事項の確認をしてください',
      img_url: usingImg1,
    },
    {
      step: 'STEP 2',
      title: 'オンライン診察',
      content:
        '医師とオンライン診療を受けて、あなたに最適な治療法(内服/外用薬)の提案を受けてください',
      img_url: usingImg2,
    },
    {
      step: 'STEP 3',
      title: 'お薬を処方・決済',
      content:
        '治療法(内服/外用薬)の内容を確認し処方を開始したい場合決済を完了させてください',
      img_url: usingImg3,
    },
    {
      step: 'STEP 4',
      title: '本人確認書類の提出',
      content:
        '本人確認書類のアップロードをしてください(保険証・免許証・マイナンバーカード)',
      img_url: usingImg4,
    },
    {
      step: 'STEP 5',
      title: '最短で発送',
      content: 'お薬が届いたら服用を開始してください。',
      img_url: usingImg5,
    },
  ]
  return (
    <Wrapper id="using" className="pl-30 pr-30 pb-30">
      <h4 className="lp-page__header mb-30" data-aos="fade-down">
        BIOPORT+の利用方法
      </h4>
      <div className="using-list">
        {usingList.length > 0 &&
          usingList.map((item, index) => {
            return (
              <div
                className="using-item d-flex mt-4"
                key={index}
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <img src={item.img_url} alt="using-image-1" />
                <div className="using-item__ctn ml-20">
                  <h6>{item.step}</h6>
                  <h4 className="mb-10 mt-1">{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              </div>
            )
          })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  img {
    width: 120px;
    height: 120px;
  }

  .using-item {
    &__ctn {
      h6 {
        color: #00ae91;
        font-size: 12px;
        line-height: 22px;
        font-weight: bold;
      }

      h4 {
        letter-spacing: 0.06em;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
      }

      p {
        font-size: 13px;
        line-height: 22px;
        white-space: pre-wrap;
      }
    }
  }
`

export default UsingComponent
