import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ButtonCustom from 'src/components/form/Button'
import { BASE_WEB_CLINICS_URL } from 'src/utils/constant'
import styled from 'styled-components'

const GreenButtonBooking = () => {
  useEffect(() => {
    document.body.addEventListener('touchmove', scrollHandle)
    window.addEventListener('scroll', scrollHandle)
  })

  const { id } = useParams()

  const scrollHandle = () => {
    const bookingElement: any = document.getElementById('booking-online')
    const { scrollTop, scrollHeight } = document.body
    const { innerHeight } = window

    if (scrollTop + innerHeight >= scrollHeight) {
      bookingElement.classList.add('hide')
      return
    }
    bookingElement.classList.remove('hide')
  }

  return (
    <Wrapper>
      <div id="booking-online" className="bg-aero-blue py-3 px-4">
        <p className="text-center cl-chestnut-rose font-weight-bold fs-18">
          ＼お悩みを医師に無料相談／
        </p>
        <ButtonCustom
          className="btn-default btn-persian-green mt-2 fs-16"
          loading={false}
          onClick={() => window.open(`${BASE_WEB_CLINICS_URL}/${id}`)}
          name="オンライン診察を予約する"
          showIconRight={true}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  #booking-online {
    width: 100%;
    opacity: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1001;
    transition: opacity 0.3s linear;
    flex: 0 0 50%;
    max-width: 50%;
    left: 25%;
    z-index: 2;

    .ant-btn,
    p {
      letter-spacing: 0.2em;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      left: 0;
    }
  }

  .hide {
    opacity: 0 !important;
  }
`

export default GreenButtonBooking
