import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Card, Tag } from 'antd'
import { Plan } from 'src/entities/plan'
import { resPlans } from '../utils/medicine'

const ListMedicinesComponent: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([])

  useEffect(() => {
    // Call api
    const newPlans = resPlans.map((item: any) => new Plan().deserialize(item))
    setPlans(newPlans)
  }, [])
  return (
    <Wrapper id="list-medicine" className="medicines">
      <HeaderStyle>
        <div className="medicines__header">
          <div className="medicines__title">処方されるお薬例</div>
          <div className="mb-30 p-20">
            「医薬品」は有効成分の効果が認められたもので、お肌の治療または予防に使用されます。市販の化粧品やサプリメントは効能効果は劣り、補助としてしか効きません。
          </div>
        </div>
      </HeaderStyle>
      <div className="medicines__content">
        {plans.map((plan) => {
          return (
            <Card key={plan.id} className="medicines__item">
              <div className="medicines__title">{plan.name}</div>
              <div className="medicines__des">{plan.detail}</div>
              <div className="medicines__action">
                {plan.troubles.map((item, index) => {
                  return (
                    <Tag
                      key={index}
                      color="var(--color-gurkha-dark)"
                      className="p-5 fs-16 font-weight-bold"
                    >
                      {item}
                    </Tag>
                  )
                })}
              </div>
              <div className="medicines__drug mb-4">
                {plan.medicines.map((item) => {
                  return (
                    <div className="medicines__drug-item" key={item.id}>
                      <img src={item.image_url} alt="" />
                      <div className="medicines__drug-name">{item.name}</div>
                    </div>
                  )
                })}
              </div>

              <div className="medicines__price">
                月額
                <span className="medicines__price-item">
                  {plan.priceString}
                </span>
                <span className="fs-13 cl-chestnut-rose">(税込)</span>
              </div>
            </Card>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  text-align: center;

  .ant-tag {
    min-width: 80px;
  }

  .medicines {
    &__header {
      padding-bottom: 30px;
    }

    &__content {
      margin-top: -80px;
      padding: 0 20px;
      margin-bottom: 20px;
    }
    &__item {
      margin-top: 20px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    &__title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin: 20px 0;
    }

    &__action {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // gap: 15px;
      margin: 20px auto;

      button {
        width: 25% !important;
        border-radius: unset;
        padding: 10px;
        height: 40px !important;
        font-weight: bold;
      }
    }

    &__drug {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      &-item {
        width: 28%;
      }

      &-name {
        font-size: 13px;
        text-decoration-line: underline;
      }

      img {
        margin-bottom: 10px;
      }
    }

    &__price {
      &-item {
        font-size: 24px;
        font-weight: bold;
        margin-left: 10px;
        color: var(--color-chestnut-rose);
      }
    }

    &__des {
      white-space: pre-wrap;
    }
  }
`
const HeaderStyle = styled.div`
  background-color: #ccefe9;
  padding-top: 60px;
`
export default ListMedicinesComponent
