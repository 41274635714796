import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import 'antd/dist/antd.less'
import './assets/global.scss'

import App from './App'
import { store } from './store'
import history from 'src/libs/history'
import { ErrorFallback } from 'src/app/exception/ErrorBoundary'

import TagManager from 'react-gtm-module'
import { GTMID } from 'src/constants/env-constant'

TagManager.initialize({
  gtmId: GTMID,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <Router history={history}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
)
