import React, { useRef } from 'react'
import styled from 'styled-components'

import { Carousel } from 'antd'
import { formatNumPrice } from 'src/utils/helper'

import iconNext from 'src/assets/images/next.svg'
import iconPre from 'src/assets/images/pre.svg'
import { useSelector } from 'react-redux'

const MedicineSliderComponent: React.FC = () => {
  const slider = useRef<any>(null)

  const { plans }: any = useSelector((state: any) => state.clinic)

  return (
    <Wrapper id="list-medicine" className="medicines">
      <HeaderStyle>
        <div className="medicines__header">
          <div className="medicines__title" data-aos="fade-down">
            処方されるお薬例
          </div>
          <div className="mb-70 p-20" data-aos="fade-down" data-aos-delay={100}>
            「医薬品」は有効成分の効果が認められたもので、お肌の治療または予防に使用されます。市販の化粧品やサプリメントは効能効果は劣り、補助としてしか効きません。
          </div>
        </div>
      </HeaderStyle>
      <div
        className="medicines__content"
        data-aos="fade-up"
        data-aos-delay={400}
      >
        <div className="position-relative">
          <span
            onClick={() => slider.current.prev()}
            className="action action__pre"
          >
            <img src={iconPre} alt="" srcSet="" />
          </span>
          <span
            className="action action__next"
            onClick={() => slider.current.next()}
          >
            <img src={iconNext} alt="" srcSet="" />
          </span>
          <Carousel
            ref={slider}
            centerMode={true}
            centerPadding={'25px'}
            dots={false}
          >
            {plans.map((plan) => {
              return (
                <div className="p--5" key={plan.id} id={'plan-' + plan.id}>
                  <div className="product__item" style={{ height: '600px' }}>
                    <img
                      src={plan.image_url}
                      alt=""
                      style={{
                        width: '100%',
                        height: '50%',
                        objectFit: 'contain',
                      }}
                    />
                    <div
                      style={{
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        margin: '1rem',
                      }}
                    >
                      {plan.name}
                    </div>
                    <p
                      style={{
                        margin: '1rem',
                      }}
                    >
                      {plan.detail}
                    </p>
                    <div className="product__price">
                      初回 &nbsp;
                      <span
                        className="product__price-first"
                        style={{
                          color: '#CE5848',
                        }}
                      >
                        ¥{formatNumPrice(plan.price_first)}
                      </span>
                      <span className="product__price-first-tax cl-chestnut-rose">
                        (税込)
                      </span>
                    </div>
                    <div className="product__price">
                      2回目以降 &nbsp;
                      <span
                        className="product__price-second"
                        style={{
                          color: '#CE5848',
                        }}
                      >
                        ¥{formatNumPrice(plan.price_after)}
                      </span>
                      <span className="product__price-second-tax cl-chestnut-rose">
                        (税込)
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  text-align: center;

  .ant-tag {
    min-width: 80px;
  }

  .medicines {
    &__header {
      padding-bottom: 30px;
    }
    &__content {
      margin-top: -100px;
      margin-bottom: 20px;
    }
    &__description {
      min-height: 50px;
    }
    &__item {
      min-height: 600px;
      margin-top: 20px 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    &__title {
      font-weight: bold;
      text-align: center;
      font-weight: 700;
      font-size: 26px;
    }

    &__action {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      margin: 10px auto;

      button {
        width: 25% !important;
        border-radius: unset;
        padding: 10px;
        height: 40px !important;
        font-weight: bold;
      }
    }

    &__drug {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      img {
        margin-bottom: 10px;
        width: 100%;
      }

      &-img {
        background-color: #ead9c7;
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 10px;
      }

      &-img:nth-child(2) {
        background-color: #eedcaf;
        border-radius: 50%;
      }

      &-item {
        width: 30%;

        &:nth-child(2) .medicines__drug-img {
          background-color: #eedcaf;
        }

        &:nth-child(3) .medicines__drug-img {
          background-color: #b2dde5;
        }

        &:nth-child(4) .medicines__drug-img {
          background-color: #ffe7e5;
        }

        &:nth-child(5) .medicines__drug-img {
          background-color: #f8ddca;
        }
      }

      &-name {
        font-size: 13px;
        text-decoration-line: underline;
      }
    }

    &__price {
      &-item {
        font-size: 22px;
        font-weight: bold;
        margin-left: 10px;
        color: var(--color-chestnut-rose);
      }
    }

    &__description {
      white-space: pre-wrap;
    }
  }

  .action {
    z-index: 1;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;

    &__pre {
      left: 5px;
    }

    &__next {
      right: 5px;
    }
  }

  .product {
    &__price {
      &-first {
        font-family: YuGothic;
        font-size: 27px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0.1em;
        text-align: center;

        &-tax {
          font-family: YuGothic;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0.1em;
          text-align: center;
        }
      }
      &-second {
        font-family: YuGothic;
        font-size: 28px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0.1em;
        text-align: center;

        &-tax {
          font-family: YuGothic;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: 0.1em;
          text-align: center;
        }
      }
    }
  }
`
const HeaderStyle = styled.div`
  background-color: var(--color-yuma);
  padding-top: 40px;
`
export default MedicineSliderComponent
