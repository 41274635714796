import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Col, Form, Row, Select, Typography } from 'antd'
import cs from 'classnames'

import ButtonCustom from 'src/components/form/Button'
import { InputField } from 'src/components/form'
import { ContactInterface } from 'src/interfaces/contact'
import { useContact } from '../form/contactForm'
import { contactTypes } from '../form/contactType'

const ContactComponent: React.FC = () => {
  const { form, onSubmit } = useContact()
  const { control, handleSubmit } = form
  const textareaInputDoctor: any = useRef(null)
  const [contentText, setContentText] = useState('')
  const [error, setError] = useState('')

  const handleChangeType = (value) => {
    if (value === '') {
      setError('必須項目が入力されていません')
    } else {
      setError('')
    }
    form.setValue('contact_type', value)
  }

  const handleChangeContentText = (event) => {
    form.setValue('content', event.target.value)
    setContentText(event.target.value)
  }

  const autoResizeTextarea = () => {
    textareaInputDoctor.current.style.height = '32px'
    textareaInputDoctor.current.style.height =
      textareaInputDoctor.current.scrollHeight + 'px'
  }

  const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('android') > -1
  }

  return (
    <Wrapper id="contact">
      <div className="contact-header mb-20" data-aos="fade-down">
        お問い合わせ
      </div>

      <StyleForm data-aos="fade-up">
        <Row>
          <Col xs={24}>
            <label className="font-normal">メールアドレス</label>
          </Col>
          <Col xs={24}>
            <InputField<ContactInterface>
              controller={{
                control,
                name: 'email',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'email',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <label className="font-normal">お名前</label>
          </Col>
          <Col xs={24}>
            <InputField<ContactInterface>
              controller={{
                control,
                name: 'name',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'text',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <label className="font-normal">問い合わせ種別</label>
          </Col>
          <Col xs={24} className="mb-2">
            <Select
              className="aga-select mt-2"
              defaultValue={contactTypes[0].value}
              options={contactTypes}
              onChange={handleChangeType}
            />
            {error && (
              <Typography.Text
                className="ant-form-item-explain ant-form-item-explain-error"
                type="danger"
                ellipsis={{ tooltip: true }}
              >
                {error}
              </Typography.Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <label className="font-normal">お問い合わせ内容</label>
          </Col>
          <Col xs={24}>
            <textarea
              className={cs({
                'new-message-form__message-input': true,
                'new-message-form__message-input__no-camera': isAndroid(),
                'aga-input p--20': true,
              })}
              value={contentText}
              rows={1}
              ref={textareaInputDoctor}
              placeholder="入力してください"
              onChange={handleChangeContentText}
              onBlur={autoResizeTextarea}
            />
          </Col>
        </Row>

        <Row className="mt-30">
          <ButtonCustom
            className="btn-default btn-persian-green mt-35 w-100 mg-auto bg-armadillo"
            loading={false}
            onClick={handleSubmit(onSubmit)}
            showIconRight
            name="送信する"
          />
        </Row>
      </StyleForm>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  margin-top: 30px;
  background-color: #f3f3f2;

  .contact-header {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-top: 50px;
  }
`

const StyleForm = styled(Form)`
  padding: 0 20px 30px 20px;
  & input {
    padding: 15px !important;
  }
  & .link {
    display: block;
    width: 100%;
    color: #000000;
    text-align: center;
    text-decoration: underline;
    margin-top: 50px;
  }
  & .btn-submit {
    font-size: 18px;
  }
  & label {
    font-size: 15px !important;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }
`

export default ContactComponent
