import React from 'react'
import styled from 'styled-components'
import recommendIcon from 'src/assets/images/recommend-icon.svg'

const RecommendComponent: React.FC = () => {
  const recommendList = [
    '仕事などが忙しく、\nクリニックに行く時間がない方',
    'クリニック・薬局などの\n待ち時間がもったないと思う方',
    'スキマ時間を有効活用したい方',
    '薬をすぐに処方してもらいたい方',
  ]

  return (
    <Wrapper className="recommend pl-30 pr-30 pb-30" data-aos="fade-up">
      <h4 className="lp-page__header">こんな方におすすめ</h4>
      <div className="recommend__list mt-30">
        {recommendList.length > 0 &&
          recommendList.map((item, index) => {
            return (
              <p className="recommend__item mb-15 fs-16" key={index}>
                {item}
              </p>
            )
          })}
      </div>
      <div className="recommend__footer mt-30">
        <h4 className="font-weight-bold fs-15 text-center">
          そんな時は、オンラインサービスの
          <span className="text-logo">BIOPORT+へ！</span>
        </h4>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #f0faf8;

  .recommend {
    &__item {
      border: 0.5px solid #0e0d0a;
      padding: 0 25px;
      background-color: #fff;
      white-space: pre-wrap;
      height: 87px;
      display: flex;
      align-items: center;
    }

    &__footer {
      position: relative;

      &:after {
        content: '';
        width: 63px;
        height: 34px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: -50px;
        background: url(${recommendIcon}) no-repeat;
      }
    }
  }

  .text-logo {
    font-size: 26px;
    letter-spacing: 0.1em;
  }
`

export default RecommendComponent
