import React from 'react'
import styled from 'styled-components'
import LPHeader from 'src/components/layout/Header/LPHeader'
import {
  HeaderComponent,
  BookingComponent,
  AboutComponent,
  RecommendComponent,
  AdvantageComponent,
  DrugsComponent,
  UsingComponent,
  ClinicComponent,
  FAQComponent,
  ContactComponent,
  FooterComponent,
  MedicineSliderComponent,
} from './components'
import ButtonBooking from 'src/components/layout/Header/components/ButtonBooking'

const IntroContainer: React.FC = () => {
  return (
    <Wrapper>
      <LPHeader />
      <BodyStyle className="medicines-lp">
        <HeaderComponent />
        <BookingComponent />
        <AboutComponent />
        <RecommendComponent />
        <AdvantageComponent />
        <DrugsComponent />
        <UsingComponent />
        <MedicineSliderComponent />
        {/* <ExampleComponent /> */}
        <ClinicComponent />
        <FAQComponent />
        <ContactComponent />
        <BookingComponent />
        <FooterComponent />
      </BodyStyle>
      <ButtonBooking />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .medicines-lp {
    overflow-x: hidden;
  }
`
const BodyStyle = styled.div`
  /* padding: 20px; */
`

export default IntroContainer
