import { combineReducers } from 'redux'

import domain from './domain'
import ui from './ui'
import { reducer as clinic } from './clinic'

export default combineReducers({
  domain,
  ui,
  clinic,
})
