import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import clinicRequest from 'src/api/requests/clinic'
import LoadingSuspense from 'src/components/application/LoadingSuspense'
import IntroContainer from 'src/containers/Top/Intro'
import GreenIntroContainer from 'src/containers/TopGreen/Intro'
import { store } from 'src/store'
import { BASE_WEB_CLINICS_URL } from 'src/utils/constant'

const IntroPage: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { template } = useSelector((state: any) => state.clinic)

  useEffect(() => {
    if (!id) {
      navigate('/')
      return
    }
    clinicRequest.getClinicDetail(id).then(({ data }) => {
      store.dispatch({ type: 'clinic/setClinic', payload: data })
    })
  }, [])

  useEffect(() => {
    if (template === 'none')
      window.open(`${BASE_WEB_CLINICS_URL}/${id}`, '_self')
  }, [template])

  return (
    <>
      {(template &&
        {
          default: <IntroContainer />,
          main_color_green: <GreenIntroContainer />,
        }[template]) || <LoadingSuspense />}
    </>
  )
}

export const PATH = '' as const
export default IntroPage
