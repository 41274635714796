import { createSlice } from '@reduxjs/toolkit'

type State = {
  template: string | null
  logoUrl: string
  mainImageUrl: string
  plans: Array<any>
}

const initialState: State = {
  template: null,
  logoUrl: '',
  mainImageUrl: '',
  plans: [],
}

const { actions, reducer } = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    setClinic: (state, action) => {
      state.template = action.payload.template
      state.logoUrl = action.payload.logo_lp_url
      state.mainImageUrl = action.payload.image_lp_url
      state.plans = action.payload.plans
    },
  },
})

export { reducer }
export default actions
