import clinic_2 from 'src/assets/images/landing/clinic_2.svg'
import clinic_3 from 'src/assets/images/landing/clinic_3.svg'
import doctor_1 from 'src/assets/images/landing/doctor_1.svg'
import clinicLogoHj from 'src/assets/images/landing/clinic_logo_hj.svg'

export const clinicsData = {
  lpClinics: [{ uuid: 'hj', logo: clinicLogoHj }],
  clinics: [
    {
      name: 'HADA LOUNGEクリニック　新宿院',
      address: '東京都新宿区新宿3-30-13 新宿マルイ 本館 5F',
      access:
        '新宿三丁目 / 東京メトロ丸ノ内線 A4口 40m 約 1分\n新宿三丁目 / 都営新宿線 A1口 68m 約 2分\n新宿三丁目 / 東京メトロ副都心線 A1口 68m 約 2分\n新宿 / 東京メトロ丸ノ内線 A6口 197m 約 3分\n新宿 / ＪＲ山手線 東口 324m 約 5分',
      business_hours: '11：00～20：00',
      note: '※営業時間は変更されることがあります。',
      image_url: clinic_2,
      id: 1,
    },
    {
      name: 'HADA LOUNGEクリニック　夙川院',
      address: '兵庫県西宮市大谷町7番1号 夙川グリーンプレイスA2-c',
      access:
        '阪急神戸本線、甲陽線 夙川駅から徒歩で19分\nJR神戸線 芦屋駅、さくら夙川駅から徒歩で19分',
      business_hours: '9：00～21：00',
      close_time: '',
      note: '※営業時間は変更されることがあります。',
      image_url: clinic_3,
      id: 3,
    },
  ],
  doctors: [
    {
      clinic_name: 'HADA LOUNGEクリニック 夙川院　院長',
      name: 'うめはら あつし',
      name_kana: '梅原 淳',
      career_introduction:
        '1994年 3月 　\t 金沢医科大学 卒業 \n 1998年 4月 　\t 兵庫医科大学病院 放射線科 入職 \n 2000年 4月 　\t 神戸大学大学院 第二内科 入職 \n 2002年 4月 　\t 国家公務員共済組合連合会 六甲病院 入職 \n 2005年 8月 　\t 医療法人社団 梅原医院 入職 \n 2013年 5月 　\t うめはら内科クリニック 開院',
      image_url: doctor_1,
      id: 1,
    },
  ],
}
