import medicine_1 from 'src/assets/images/medicine/medicine_1.png'
import medicine_2 from 'src/assets/images/medicine/medicine_2.png'
import medicine_3 from 'src/assets/images/medicine/medicine_3.png'
import medicine_4 from 'src/assets/images/medicine/medicine_4.png'
import medicine_5 from 'src/assets/images/medicine/medicine_5.png'
import medicine_6 from 'src/assets/images/medicine/medicine_6.svg'

export const medicine1 = {
  id: 1,
  name: 'シーピー',
  image_url: medicine_1,
}
export const medicine2 = {
  id: 2,
  name: 'ノイロビタン',
  image_url: medicine_2,
}
export const medicine3 = {
  id: 3,
  name: 'ハイチオール',
  image_url: medicine_3,
}
export const medicine4 = {
  id: 4,
  name: 'ユベラ',
  image_url: medicine_4,
}
export const medicine5 = {
  id: 5,
  name: 'ディフィリン',
  image_url: medicine_5,
}
export const medicine6 = {
  id: 6,
  name: 'ビオチン散',
  image_url: medicine_6,
}
export const medicine7 = {
  id: 7,
  name: 'トランサミン250ｍｇ',
  image_url: medicine_2,
}
export const medicine8 = {
  id: 8,
  name: 'ハイドロキノン４％',
  image_url: medicine_4,
}
export const medicine9 = {
  id: 9,
  name: 'トレチノイン0.025% 20mg',
  image_url: medicine_5,
}
export const medicine10 = {
  id: 10,
  name: 'ディフェリン',
  image_url: medicine_5,
}
export const medicine11 = {
  id: 11,
  name: 'ミヤBM（酪酸菌）',
  image_url: medicine_5,
}
export const medicine12 = {
  id: 12,
  name: 'ハイドロキノン４％',
  image_url: medicine_6,
}

export const resPlans = [
  {
    id: 1,
    name: '肌ケアセット①',
    description:
      'ゆらぎのない肌 \n お肌の基礎力を高めて肌トラブルを抑え \n る―シミ　くすみ　シワ',
    medicines: [medicine1, medicine2, medicine3],
    troubles: ['悩みのない肌'],
    price: 4440,
  },
  {
    id: 2,
    name: '肌ケアセット②',
    description:
      'ゆらぎのない肌 \n お肌の基礎力を高めて肌トラブルを抑え \n る―シミ　くすみ　シワ',
    medicines: [medicine1, medicine2, medicine3, medicine4],
    troubles: ['悩みのない肌'],
    price: 5020,
  },
  {
    id: 3,
    name: 'ニキビケアセット',
    description: '現時点でのニキビ治療にとっかしたもの',
    medicines: [medicine1, medicine2, medicine3, medicine4, medicine10],
    troubles: ['ニキビ'],
    price: 6850,
  },
]
