import format from 'string-format'
import HTTPRequest from '../index'

class Clinic extends HTTPRequest {
  getClinicDetail(uuid: string) {
    return this.get(format('api/v2/guests/lp/{0}', uuid))
  }
}

export default new Clinic()
