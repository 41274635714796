import React from 'react'
import { useSelector } from 'react-redux'
import LPHeader from 'src/components/layout/Header/LPHeader'
import GreenButtonBooking from 'src/components/layout/Header/components/GreenButtonBooking'
import styled from 'styled-components'
import {
  AboutComponent,
  AdvantageComponent,
  BookingComponent,
  ClinicComponent,
  ContactComponent,
  DrugsComponent,
  FAQComponent,
  FooterComponent,
  HeaderComponent,
  ProductSliderComponent,
  RecommendComponent,
  UsingComponent,
} from './components'

const IntroContainer: React.FC = () => {
  const { mainImageUrl }: any = useSelector((state: any) => state.clinic)

  return (
    <Wrapper className="top-green">
      <LPHeader />
      <BodyStyle className="medicines-lp">
        <img src={mainImageUrl} alt="" className="main-image" />
        <ProductSliderComponent />
        <HeaderComponent />
        <BookingComponent />
        <AboutComponent />
        <RecommendComponent />
        <AdvantageComponent />
        <DrugsComponent />
        <UsingComponent />
        <ClinicComponent />
        <FAQComponent />
        <ContactComponent />
        <BookingComponent />
        <FooterComponent />
      </BodyStyle>
      <GreenButtonBooking />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .medicines-lp {
    overflow-x: hidden;
  }
  .main-image {
    width: 100%;
  }
`
const BodyStyle = styled.div`
  /* padding: 20px; */
`

export default IntroContainer
