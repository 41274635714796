import React from 'react'
import { PATH } from 'src/pages/top'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import GuestLayout from 'src/routes/components/GuestLayout'

const ErrorRoute: IRouter[] = [
  {
    path: 'preview',
    element: loadable(() => import('src/pages/top/Preview')),
  },
  {
    path: ':id',
    element: <GuestLayout />,
    children: [
      {
        path: PATH.INTRO,
        element: loadable(() => import('src/pages/top/Intro')),
      },
      {
        path: PATH.MEDICINE,
        element: loadable(() => import('src/pages/top/Medicine')),
      },
    ],
  },
]

export default ErrorRoute
