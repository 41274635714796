import React from 'react'
import styled from 'styled-components'
import banerImg from 'src/assets/images/banner.svg'
import banerDrugImg from 'src/assets/images/banner-drug.png'
import banerImgBy from 'src/assets/images/by-bioport.svg'
import bannerBgBottom from 'src/assets/images/banner-bg-bottom-white.png'
import { useSelector } from 'react-redux'

const HeaderComponent: React.FC = () => {
  const { logoUrl }: any = useSelector((state: any) => state.clinic)

  return (
    <>
      <h1
        style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: '600',
          marginTop: '4rem',
        }}
      >
        New Product
      </h1>
      <Banner className="banner">
        <img
          src={logoUrl}
          alt=""
          style={{
            position: 'absolute',
            top: '5%',
            maxWidth: '40%',
            maxHeight: '70px',
            left: '5%',
          }}
        />
        <div className="banner__bg"></div>
        <div className="banner__wrp px-3">
          <div className="banner__slogan" data-aos="zoom-in">
            <img src={banerDrugImg} alt="banner-drug" />
          </div>
          <div
            className="banner__ctn px-3"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <p className="mb-5 text-22">処方薬/医療用医薬品</p>
            <p className="mb-5 text-32">
              お届けまで、
              <br />
              すべてオンラインで
            </p>
            <div className="banner-by mb-15">
              <img src={banerImgBy} alt="banner-by" />
            </div>
            <div className="banner__ctn-item-wrp">
              <p className="banner__ctn-item">
                夜10時まで
                <br />
                オンライン
                <br />
                診察可能
              </p>
              <p className="banner__ctn-item">
                土日祝も
                <br />
                OK
              </p>
              <p className="banner__ctn-item">
                診察料
                <span>
                  <span className="lh-32 banner__fee">0</span> 円
                </span>
              </p>
            </div>
          </div>
        </div>
      </Banner>
    </>
  )
}

const Banner = styled.div`
  position: relative;
  padding-bottom: 124px;

  .banner {
    &__bg {
      background: url(${banerImg}) no-repeat;
      background-size: 100%;
      background-position: center;
      height: 646px;
    }

    &__slogan {
      position: absolute;
      top: 30%;
      left: 0;

      p {
        height: 100px;
        width: 100px;
        border: 0.5px solid #0e0d0a;
        border-radius: 50%;
        font-size: 12px;
        margin: 0;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        position: absolute;
        top: -23%;
        left: 41%;

        &:after {
          content: '';
          width: 14px;
          height: 1px;
          background: #0e0d0a;
          position: absolute;
          left: 15px;
          bottom: 7px;
          transform: rotate(130deg);
        }
      }
    }

    &__ctn {
      background: url(${bannerBgBottom}) no-repeat;
      background-size: 100%;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 21px;
      padding-bottom: 20px;

      &-item-wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-item {
        width: 105px;
        height: 105px;
        border: 1px solid #424037;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 13px;
        margin: 0;
        font-weight: bold;
      }
    }

    &__fee {
      font-size: 32px;
      line-height: 32px;
    }
  }

  .text-22 {
    font-size: 22px;
  }

  .text-32 {
    font-size: 32px;
  }

  .banner-by {
    text-align: right;
    margin-right: 8%;
  }

  @media (max-width: 380px) {
    padding-bottom: 140px;

    .banner {
      &__bg {
        height: 505px;
      }
    }
  }

  @media (max-width: 320px) {
    padding-bottom: 50px;

    .banner {
      &__bg {
        height: 450px;
      }

      &__slogan {
        p {
          height: 80px;
          width: 80px;
          font-size: 10px;
        }

        img {
          width: 80%;
        }
      }

      &__ctn {
        padding-bottom: 0;

        &-item {
          width: 90px;
          height: 90px;
          font-size: 11px;
        }
      }

      &__fee {
        font-size: 28px;
        line-height: 28px;
      }
    }

    .text-22 {
      font-size: 14px;
    }

    .text-32 {
      font-size: 22px;
    }
  }
`

export default HeaderComponent
