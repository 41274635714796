import React from 'react'
import styled from 'styled-components'

import logo from 'src/assets/images/landing/logo.svg'
import { useParams } from 'react-router-dom'
import {
  REACT_APP_BASE_SHEEP_URL,
  REACT_APP_BASE_WEB_URL,
} from 'src/constants/env-constant'

const CONTACT_LINK = '#contact'

const FooterComponent: React.FC = () => {
  const paramsUrl = useParams()

  return (
    <Wrapper>
      <div className="footer-head">
        <img src={logo} alt="" />
      </div>
      <div className="footer-content">
        <p
          onClick={() =>
            window.location.assign(`${REACT_APP_BASE_SHEEP_URL}/about`)
          }
          className="c-pointer"
        >
          会社概要
        </p>
        <p
          onClick={() =>
            window.location.assign(`${REACT_APP_BASE_WEB_URL}/policy`)
          }
          className="c-pointer"
        >
          プライバシーポリシー
        </p>
        <p
          onClick={() =>
            window.location.assign(`${REACT_APP_BASE_WEB_URL}/terms`)
          }
          className="c-pointer"
        >
          利用規約
        </p>
        <p
          onClick={() =>
            window.location.assign(`${REACT_APP_BASE_WEB_URL}/legal-notice`)
          }
          className="c-pointer"
        >
          特定商法取引法に基づく表記
        </p>
        <p
          onClick={() =>
            window.location.replace(`/${paramsUrl.id}${CONTACT_LINK}`)
          }
          className="c-pointer"
        >
          お問い合わせ
        </p>
        <p
          onClick={() =>
            window.open(`${REACT_APP_BASE_WEB_URL}/my-page/other-menu`)
          }
          className="c-pointer"
        >
          マイページ
        </p>
      </div>
      <p className="text-center">© BIOPORT+ All Rights Reserved.</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #ccefe9;

  .footer-head {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 50px auto 30px;

    img {
      height: 40px;
    }
  }

  .footer-content {
    text-align: center;
    font-weight: bold;
    margin: 50px auto;

    p {
      padding: 20px;
    }
  }
`

export default FooterComponent
