import React from 'react'
import styled from 'styled-components'
import LPHeader from 'src/components/layout/Header/LPHeader'
import {
  BookingComponent,
  FooterComponent,
  ListMedicinesComponent,
} from './components'
import ButtonBooking from 'src/components/layout/Header/components/ButtonBooking'

const MedicineContainer: React.FC = () => {
  return (
    <Wrapper>
      <LPHeader hasMenu={false} />
      <ListMedicinesComponent />
      <BookingComponent />
      <FooterComponent />
      <ButtonBooking />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default MedicineContainer
