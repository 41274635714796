import React from 'react'
import { Button } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons'

import iconRight from 'src/assets/icons/right_white.svg'

type Props = {
  loading: boolean
  onClick?: any
  className: string
  name: string
  htmlType?: any
  disabled?: boolean
  id?: string
  ref?: any
  showIconRight?: boolean
}

const ButtonCustom = (props: Props) => {
  const {
    loading,
    onClick,
    className,
    name,
    htmlType,
    disabled = false,
    id,
    ref,
    showIconRight,
  } = props
  const handleOnClick = (event) => {
    onClick()
    event.target.blur()
  }

  return (
    <Button
      style={{
        width: '100%',
        height: '60px',
        fontSize: '18px',
        fontWeight: '700',
        position: 'relative',
      }}
      id={id}
      ref={ref}
      className={className}
      type="primary"
      loading={loading}
      onClick={(event) => {
        handleOnClick(event)
      }}
      htmlType={htmlType ? htmlType : 'text'}
      disabled={disabled}
      icon={loading ? <PoweroffOutlined /> : null}
    >
      {name}
      {showIconRight && (
        <img
          style={{
            position: 'absolute',
            right: '15px',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
          }}
          src={iconRight}
          alt=""
        />
      )}
    </Button>
  )
}

export default ButtonCustom
