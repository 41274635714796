import React from 'react'
import styled from 'styled-components'

// images
import booking from 'src/assets/images/medicine/booking.svg'

// components
import ButtonCustom from 'src/components/form/Button'
import { BASE_WEB_CLINICS_URL } from 'src/utils/constant'
import { useParams } from 'react-router-dom'

const BookingComponent: React.FC = () => {
  const { id } = useParams()
  return (
    <Wrapper data-aos="fade-in" data-aos-delay="50">
      <div className="booking" data-aos="fade-up" data-aos-delay="100">
        <div className="booking__title">オンライン診療のご予約</div>

        <div className="booking__content">
          ＼興味はあるけど &hellip;／
          <br />
          「自分にはどんな治療・お薬が合うかわからない」
          <br />
          「注文は相談してからしたい」
          <br />
          <br />
          まずはお気軽にご相談ください！
          <br />
        </div>

        <ButtonCustom
          className="btn-default btn-gurkha mt-20 fs-13"
          loading={false}
          name="オンライン診察を予約する"
          showIconRight={true}
          onClick={() =>
            (window.location.href = `${BASE_WEB_CLINICS_URL}/${id}`)
          }
        />

        <div className="booking__time">
          診察時間 9:00〜22:00（土日祝も休まず診察）
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flow-root;
  background-image: url(${booking});
  background-repeat: no-repeat;
  background-size: cover;

  .booking {
    background-color: var(--color-white);
    margin: 230px 15px 15px;
    padding: 15px;
    text-align: center;

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      margin-top: 20px;
      color: var(--color-gurkha);
    }

    &__content {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: var(--color-black);
      margin-top: 20px;
    }

    &__time {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-black);
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
`

export default BookingComponent
