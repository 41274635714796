import React from 'react'
import styled from 'styled-components'
import adImg1 from 'src/assets/images/advantage-1.png'
import adImg2 from 'src/assets/images/advantage-2.png'
import adImg3 from 'src/assets/images/advantage-3.png'
import adImg4 from 'src/assets/images/advantage-4.png'

const AdvantageComponent: React.FC = () => {
  const adList = [
    {
      title: 'いつでも、どこからでも',
      content:
        '仕事、育児などで時間が無い方、通院するのが難しい方でも、オンラインでお肌の相談、診察やお薬の処方まで。\n ※ご自宅にお薬が届きます。\n 病院や薬局での待ち時間もありません。',
      img_url: adImg1,
    },
    {
      title: '初診料、再診料、処方箋料、相談料無料',
      content:
        '通常病院での診察で必要な初診料などが無料です。お薬を購入するかは相談後に決めて頂けるので、まずはお気軽にご相談ください。',
      img_url: adImg2,
    },
    {
      title: '続けやすい定期便配送',
      content:
        'お薬は定期便でお届けします。うっかり買い忘れもなく、同じ処方でしたら再診も不要です。もちろん定期便の休止もできますのでご安心ください。',
      img_url: adImg3,
    },
    {
      title: '最短で発送',
      content:
        '12時までの決済で当日発送、\n 最短翌日ポストにお届けします。\n※配送エリアにより前後いたします',
      img_url: adImg4,
    },
  ]

  return (
    <Wrapper className="advantage pt-50 pb-50 pr-30 pl-30 text-center">
      <div className="mb-60" data-aos="fade-down">
        <p className="fs-16">スマホで医師に、相談・診察・処方。</p>
        <p className="fs-20 mt-2 font-weight-bold">
          あなたにピッタリのお薬がとどく。
        </p>
      </div>
      <div className="advantage__list">
        {adList.length > 0 &&
          adList.map((item, index) => {
            return (
              <div
                className="advantage__item"
                key={index}
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <img src={item.img_url} alt="" />
                <h4 className="fs-20 font-weight-bold mt-4 mb-4">
                  {item.title}
                </h4>
                <p>{item.content}</p>
              </div>
            )
          })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .advantage {
    &__item {
      margin-bottom: 60px;

      p {
        white-space: pre-wrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default AdvantageComponent
